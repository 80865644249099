.Gallery{
    background-color: white;
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 50px ;
}
.Gallery_HeadBg{
    height: 50vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: rgb(112, 111, 111); */
    /* background-blend-mode: overlay; */
    /* background-attachment: fixed; */
    background-size: cover;
    filter: grayscale(0.2) brightness(0.8);

}
.Gallery_Heading{
    padding: 30px 0 ; 
    /* margin-bottom: 20px; */
   color:rgb(51, 51, 51) ; 
   background-color:whitesmoke ; 
   /* border-bottom: 1px solid lightgrey; */

}
.Gallery_Heading > h2{
      font-family: 'New Tegomin',
      serif;
}
.Gallery_Input{
    padding: 25px 0px;
    background-color: whitesmoke;
    margin: 0px auto;
}
#Gallery_InputImages{
    border: 1px solid lightgray;
    margin :0px  10px ;
}
.Gallery_items{
    width: fit-content;
    max-width: 90%;
    margin : auto ; 
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 359px));
    gap : 5px ;
    /* justify-content:space-between; */
}
.Gallery_item{
    /* border: 0.5px solid lightgray; */
    /* box-shadow: 2px 2px 5px lightgray; */
    height : 150px ;
    border-radius: 5px;
    overflow: hidden;
    height: fit-content;
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

.Gallery_item > img{
    width: 359px;
    max-width: 90vw;
    margin: auto;
    border: 0.5px solid lightgray;
    border-radius: 5px;
    object-fit: contain;
    backdrop-filter: blur(2px);
   
}
.Gallery_item>img:hover {
    cursor: pointer;
}

.Gallery_FullImage{
    position: fixed;
    z-index: 55;
    top: 0 ; 
    right : 0 ;
    width: 100vw ;
    height : 100vh ;   
}
.Gallery_FullImage_bg{
    position: absolute;
    top: 0;
    right : 0;
    width: 100vw;
    height : 100vh;
    z-index: 54;
    background-color: rgba(0, 0, 0, 0.8);
}
.Gallery_FullImage > img{
    max-width: 900px;
    max-height: 900px;
    min-width: 200px;
    min-height: 200px;
    background-color: whitesmoke;
    object-fit: contain;
    z-index: 55;
    position: absolute;
    left: 0;
    right: 0;
    top : 0 ;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    border : 1px solid white; 
}
.Gallery_FullImage > button{
    position: absolute;
    top : 30px ; 
    right : 20px ;
    z-index: 55;
    background-color: tomato;
    border-radius: 5px;
    border: 1px solid red ;;
 }
@media (max-width:900px) {
    .Gallery_FullImage>img {
        max-width: 95vw;
        max-height: 95vh;
    }
}

/* ----------------------- GalleryHighlights  -------------------------------- */

.GalleryHighlights {
    background-color: whitesmoke;
    margin-bottom : 20px;
    padding-bottom: 20px;
}
.GalleryHighlights_head {
    margin-bottom : 20px ; 
}
.GalleryHighlights_Line {
    width: 100%;
    margin: auto; 
    height : 3px; 
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}
.GalleryHighlights_head > h2 {
    margin: 0 10px;
    font-family: 'New Tegomin',
    serif;
    color : blue ;

}
.GalleryHighlights_Images {
     display: grid;
     width :fit-content;
     max-width: 90vw;
     margin: 20px auto;
     grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
     gap : 10px;
}
.GalleryHighlights_Image {
    width: 400px ;
    height:400px;
    object-fit: cover ; 
}