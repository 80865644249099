@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: whitesmoke; */
  background-color: #eee;
  background-color: white;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.black {
  color: black;
}
.red {
  color: tomato;
}
.white {
  color: white;
}
.green{
  color: green;
}
.blue{
  color: rgb(40, 143, 184);
}
.link{
  color: blue;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col {
  flex-direction: column;
}
.hover{
  cursor: pointer;
}
.padding {
  padding: 20px;
}
.SW-Container{
  min-height: 100vh ;
  width: 100vw;
  padding-top: 100px;
}

.left{
  text-align: left ; 
}
.dark{
  background-color: grey
}
