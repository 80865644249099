.privacypolicy{
    width: 100vw ;
    background-color: rgb(184, 179, 179) ;
    padding: 20px ;
    min-height: 100vh;
    padding-top: 200px;
}
.privacypolicy_wrapper{
    max-width: 1400px;
    margin: auto;
    text-align: left;
}