.AdminMembers{
    padding:100px 0px ;
}
.AdminMembers_tableCover{
    width: 90% ;
    overflow: scroll;
    margin: 0 auto;
}
.AdminMembers_tableCover > table {
    min-width: 1400px;
    margin: 0 auto;
}
.AdminMembers_tableCover td {
    border: 1px solid lightgrey;
    padding: 2px 5px ;

}