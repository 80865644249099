.DonationDetails{
    margin:auto;
    padding: 10px 0px;
}
.DonationDetails_bankDetails{
    width : 100%;
    padding: 0 10%;
    padding-top: 50px;
    text-align : left;
}

.DonationDetails_bankDetails > h6 {
    font-family: 'Courier New', Courier, monospace;
    font-size: 40px;
    color : tomato;
}
.DonationDetails_bankDetails > table {
    border-collapse: collapse;
    padding : 20px ;
}
.DonationDetails_bankDetails td {
    border-collapse: collapse;
    padding: 5px 20px;
    border-bottom: 0.5px solid lightgrey;
}
.DonationDetails_QR{
    height: 400px;
    max-width: 90vw;
    object-fit: contain;
}
.DonationDetails_heading {
    background-color : skyblue ;
    width: 1000px;
    max-width: 95vw;
    margin: auto;
    padding :  10px ;
}
.DonationDetails_heading > h3 {
    font-family: 'Courier New',
    Courier,
    monospace;
    font-weight: 700;
}
.DonationDetails_Donate{
    display : flex ; 
    width: 1000px;
    max-width: 95vw;
    margin: auto;
    background-color:rgb(245, 251, 253);
    border: 0.5px solid lightgrey;
    justify-content: space-between;
}
.DonationDetails_scanPay{
    background-color : white;
}
.DonationDetails_scanPay>h6 {
    padding-top: 30px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 40px;
    color: tomato;
}
@media (max-width : 900px) {
    .DonationDetails_Donate {
        flex-direction: column;
    }
    .DonationDetails_bankDetails>h6 {
        font-size: 28px;
    }
    .DonationDetails_scanPay>h6 {
        font-size: 28px;
    }
    
}