.incomplete {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8) ;
    z-index: 90;
}
.incomplete_popUp {
    position: absolute;
    width: 600px ;
    max-width: 90vw;
    background-color: whitesmoke;
    text-align: left ;
    left: 0; right : 0 ; margin : auto ; 
    padding: 20px 30px ; 
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.incomplete_popUp > button {
    background-color:#36454F;
    color: white;
    padding : 3px 10px;
    border-radius: 10px;
}