@import url('https://fonts.googleapis.com/css2?family=New+Tegomin&display=swap');
.Navbar {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
  transition: all 1s;
  /* color: rgb(145, 143, 143); */
  color: white;
  border: 0;
  font-family: 'New Tegomin',
  serif;
  
}
.Navbar-cover {
  display: flex;
  width: 90%;
  max-width: 1400px;
  margin: auto;
  height: 60px;
  justify-content: space-between;
  /* align-items: center; */
}
.NavbarLogo {
  border-radius: 50%;
  height: 99px;
  padding: 0;
  background-color: white;
  margin-left: 10px;
  margin-top: 2px;
  z-index: 60;
  transition: all 0.5s;
}
.NavbarLogoImage {
  height: 100px;
  object-fit: contain;
  transition: all 0.5s;
  z-index: 60;
}
/* @media (max-width: 600px) {
  .NavbarLogo {
     height: 49px;
  }
  .NavbarLogoImage {
    height: 50px;
  }
  
} */

.NavbarLogoImage:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.Navbar-right {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  font-weight:  800;
}
.nav-links {
  margin: auto ;

}
.nav-links:hover {
  cursor: pointer;
  color: orange;
}
.nav-donate{
  color: white;
  transition: all 0.5s;
}
.nav-donate:hover {
  transform: scale(1.05);
  color: black;
}

.Navbar-right-hambar-mobile {
  display: none;
  flex-direction: column;
  /* background-color: grey; */
  align-items: flex-end;
  height: fit-content;
  min-height: 60px;
}
.Navbar-right-hambar-trigger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
  height: 60px;
  /* background-color: hotpink; */
}
.Navbar-right-hambar-trigger :hover {
  cursor: pointer;
}
.Navbar-right-hambar-trigger > p {
  margin: -25px 0px;
  display: flex;
  padding: 0;
  color: indianred;
}
.Navbar-right-hambar-background {
  position: absolute;
  width: 50vw;
  top: 0px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  height: calc(100vh);
  animation: down 500ms forwards;
  z-index: 40;
}
.Navbar-right-hambar-list {
  background-color: indianred;
  padding: 0px 20px;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 10px;
  color: white;
  animation: down 500ms forwards;
  height: calc(100vh);
}
.backIcon {
  width: 40px;
  object-fit: contain;
  background-color: indianred;
  position: absolute;
  top: 0px;
  z-index: 50;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-left: -55vw;
  animation: move 500ms forwards;
}
@keyframes move {
  0% {
    width: 0;
  }
  100% {
    right: auto;
  }
}
.nav-link-close {
  text-align: left;
  color: black;
  font-weight: 700;
  margin: 0;
}
.Navbar-right-hambar-list_Head {
  width: 80%;
  font-size: 22px;
  margin: 0;
}
@keyframes down {
  0% {
    font-size: 0;
    width: 0;
  }
  90% {
    font-size: 0;
  }
  100% {
    font-size: 16px;
    width: 50vw;
  }
}

.nav-link-item {
  color: white;
  border-bottom: 1px solid lightgrey;
  transition: all 0.5s;
}
.nav-donate {
  background-color: #f7a234;
  padding: 8px 14px;
  border-radius: 25px;
  font-size: 16px;
  border: 0;
}
@media (max-width: 800px) {
  .Navbar-right {
    display: none;
  }
  .Navbar-right-hambar-mobile {
    display: flex;
  }
}
.nav_dropdown{
   margin: auto ; 
}

.nav-dropdown-menu{
  display: none;
  position: absolute;
  /* top : 60px ;  */
  padding: 0;
  width: 0px;
  margin-left: -80px;
  background-color: white;
  color: rgba(0, 174, 239, 1);
  border-radius: 10px;
  /* text-align: right; */
  border : 1px solid lightgray ; 
  transition: all 500ms;
  
}
.nav-dropdown-menu>p {
  border-bottom: 1px solid lightgrey ;
  margin-top: 20px;
  transition: all 500ms;
}
.nav-dropdown-menu > p:hover {
transform: translateX(-5px);
  border-bottom: 1px solid rgb(0, 174, 239);

}

.nav_dropdown > span > p {
  margin : 0 ; 
  padding: 0;
}
.nav_dropdown>span>small {
  transform: rotate(90deg);
  color: black;
  font-size: 25px;
  transition: all 500ms;
}
.nav_dropdown:hover span>small {
  transform: rotate(90deg) rotateY(180deg);
}
.nav_dropdown:hover{
  cursor: pointer;
}
.nav_dropdown:hover  .nav-dropdown-menu{
  display: block;
  padding: 20px 20px;
  padding-top: 5px;
  width: 180px;
}