.AdminUsers{
    background-color: skyblue;
    padding-top: 120px;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    flex-wrap: wrap;
}
.AdminUsers table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
    background-color:white;
    margin:auto;
}

.AdminUsers  td,
th {
    border: 1px solid lightgrey;
    text-align: left;
    padding: 8px;
}

.AdminUsers  tr:nth-child(even) {
    background-color: #dddddd;
}