.Login{
    background-color: grey;
    height: 100vh ; 
    width: 100vw;
}

.Login_form-Cover {
    position: absolute;
    margin: auto auto;
    top: 0 ; 
    bottom: 0;
    padding: 10vw;
    width: 80vw;
    max-width: 600px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    left: 0;
    right:0 ; 
    border-radius: 20px;
}
.Login_form-Cover > h4{
    margin-bottom: 30px;
}
.Login_formRow{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.Login_formRow > input {
    border : 0 ; 
    border-bottom: 1px solid grey ;
    margin-left: 20px;
}
.Login_form-Cover > small {
    text-align: left;
    width: 100%;
    color: blue;
}

.Login_form>button {
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    width: 200px;
    margin-top: 20px;
}