.footer{
    width : 100vw ; 
    background-color: orange;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: left;
}
.footer_line{
    height : 10px ; 
    width: 100vw ;
    background-color:rgb(230, 151, 5) ; 
}
@media (max-width: 900px) {
    .footer{
        justify-content: flex-start;
    }
}
.footer_intro{
    max-width: 800px ;
    text-align: left;
    padding : 20px;

}
.footer_Links{
    padding : 20px;

    text-align: left;
}