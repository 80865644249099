.Auth{
    width: 98vw ;
    display: flex ; 
    margin: 80px auto;
    padding:  5px ;
    background-color: #0063B2FF;
}
.Auth > img{   
    height: calc(100vh - 120px);
    width: 70%;
    border-right: 1px solid #0063B2FF;
    ;
}
.Auth_LoginRegister{
    width: 30%;
    height: calc(100vh - 120px);
    background-color : rgb(172, 216, 236);
    padding: 30px ;
}
@media (max-width:900px){
    .Auth {
        flex-direction: column;
    }
    .Auth>img {
        width: 100%;
        object-fit: contain;
        height: fit-content;
    }
    .Auth_LoginRegister {
        width: 100%;
        height: 100%;
    }
}

/* ---------------------------------------------------------------  Login  ------------------------------------------------ */

.AuthLogin{
    width: 100%;
    height: 100%;
    padding-top: 30%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
}
.AuthLogin > h6 {
    color : black ;
}
.AuthLogin_table{
    margin: 20px 0px;
    text-align: left;
    width: 100%;
}
.AuthLogin_table td {
    display: flex;
    flex-direction: column;
}
.AuthLogin_table label {
    color : rgb(56, 55, 55);
}
.AuthLogin_form-Cover > small {
    color : rgb(54, 54, 54) ; 
}
.AuthLogin_table input {
   max-width: 300px;
   background-color : white ;
   color : black; 
    width : 80%;
    margin-top: 3px;
    padding: 5px 20px; 
    border-radius: 5px ;
    border: 2px solid #0063B2FF;
}
.AuthLogin_form > button {
    width: 80%;
    max-width: 300px;
    margin: auto;
    background-color : skyblue;
    padding: 5px 10px;
    border : 2px solid #0063B2FF;
    border-radius: 5px;
    box-shadow: 2px 2px 10px skyblue ; 
    font-size: larger;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 20px;
}
.AuthLogin_form>button:hover {
    transform : scale(1.02); 
    box-shadow: 1px 1px 5px white;
}
.AuthRegisterMember{
    font-size: larger;
    margin: 20px 0px;
    font-family: Poppins;
}
.google_SignIn{
     width: 80%;
     max-width: 300px;
     margin: auto;
     background-color : whitesmoke;
     padding: 5px 10px;
     border : 2px solid #0063B2FF;
     border-radius: 20px;
     box-shadow: 2px 2px 10px skyblue;
     font-size: larger;
     font-weight: bold;
     font-family: 'Courier New',
     Courier,
     monospace;
     margin-bottom: 20px;
}
@media (max-width:900px) {
    .AuthLogin>h6 {
        display: none ; 
    }
   .AuthLogin {
       padding-top: 2%;
   }
   .AuthLogin_form-Cover > small {
    display: none;
   }
   .AuthLogin_form>button {
       margin-bottom: 10px;
   }
}
