.ShikshaStories{
    background-color: whitesmoke;
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 200px;
}
.ShikshaStories_Head{
    width: 100vw;
    min-height: 300px;
    background-color:grey ; 
    margin-bottom: 20px;
}
.ShikshaStories_Head > img{
    width: 100vw;
    object-fit: contain;
}
.ShikshaStories_HeadContent{
    width: 50vw;
    padding : 10px; 
    position: absolute;
    right: 0;
    overflow: hidden;
}
.ShikshaStories_Head h2{
    color: white;
    font-family: 'New Tegomin',
    serif;
    font-size: calc(40px + 2vw);
    text-shadow: 1px 1px 2px grey;
}
.ShikshaStories_Head p {
    color: black;
    font-family: Poppins;
    font-size: calc(10px + 1vw);

}
.ShikshaStories_Content{
    flex-wrap: wrap;
}
.ShikshaStory {
    max-width: 100vw;
    width: 900px;
    height: 400px ;
    margin: auto;
    background-color: white;
    /* border : 1px solid grey ;
    border-left : 0px ;
    border-right : 0px; */
    box-shadow: 4px 4px 10px lightgrey;
    border-radius: 20px;
    margin: 30px;
    overflow: hidden;
    
}

.ShikshaStory_left {
    margin-left: 0;
    width: 40%;
    height: 100%;
    overflow: hidden;
    border: 0.5px solid grey;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: rgb(80, 80, 80);
    color: white;
    justify-content: space-between;
}
.ShikshaStory_right{
    width: 60%;
    padding: 20px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.ShikshaStory_left img{
    height: 80%;
    min-height: 300px;
    width: 100%;
    object-fit: cover;

}

.ShikshaStory_left h2 {
    font-size: 20px;
}
.ShikshaStory_left h4 {
    font-size: 14px;
}
.ShikshaStory_right p {
    overflow: hidden;
    text-overflow : ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 2px;
}


@media (max-width : 600px) {
    .ShikshaStory {
        flex-direction: column;
        height: fit-content;
        margin: 16px;
        border-radius: 10px;

        border: 0.5px solid lightgray;
    }
    .ShikshaStories_Head>img {
       content : url("https://shikshawelfare.com/media/ShikshaStoriesBackground_Mobile.jpg")
    }
    .ShikshaStory_left {
        width: 100%;
        max-height: 500px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px;
        border : none;

    }
    .ShikshaStory_right {
        width: 100%;
        height: fit-content;
    }
    .ShikshaStories_Head h2 {
        font-size: xx-large ; 
    }
}

.addyourstory{
    position : fixed;
    right : 0px ;
    bottom : 10px ; 
    background-color: lightgreen;
    padding: 5px 10px;
    border-radius: 10px;
    border-top-right-radius:0 ;
    border-bottom-right-radius:0;

}
/* ------INPUT----- */

.ShikshaStories_Input{
    background-color: whitesmoke;
    margin: 50px 0px ; 

}
.ShikshaStory_input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 600px;
    max-width: 90vw;
    background-color: white;
    border: 0.5px solid lightgrey;
    margin : 5px 0px ;
    border-radius: 10px;
}
.ShikshaStory_input label{
    font-weight: 500;
}
.ShikshaStory_input label small {
    color: grey;
}
.ShikshaStory_input textarea {
    border: 0;
    width: 70%;
    border-bottom: 1px solid lightgrey ;
}
.ShikshaStory_input textarea:focus {
    outline: none;
}

.ShikshaStory_input input {
    border: 0;
    width: 70%;
    border-bottom: 1px solid lightgrey;
}

.ShikshaStory_input input:focus {
    outline: none;
}

.Story_delete{
    margin-top: auto;
}

/* ----------- MODAL ---------- */

.ShikshaHeadStory{
    width: 100vw;
    /* padding: 0 5vw; */
    height: 600px;
    margin: auto;
    background-color: white;
    color: #333333;
    margin-top: -20px;
    border-bottom: 1px solid black;
    transition: all 2s ;
}
.ShikshaHeadStory_left {
    margin-left: 0;
    width: 30%;
    height: 100%;
    overflow: hidden; 
    color: white;
    background-color: #333333;
    justify-content: space-between;
}


.ShikshaHeadStory_left img {
    height: 85%;
    width: 100%;
    object-fit: cover;
}

.ShikshaHeadStory_left h2 {
    font-size: 30px;
    margin-bottom: 5px;
}

.ShikshaHeadStory_left h4 {
    font-size: 20px;
}
.ShikshaHeadStory_right {
    width: 70%;
    padding: 40px;
    padding-right: 80px;
    font-size: 20px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
      
}
.ShikshaHeadStory_right p {
    overflow-y : scroll;
}
@media (max-width : 600px) {
    .ShikshaHeadStory {
        flex-direction: column;
        width: 100vw;
        height: fit-content;
        border-radius: 0px;
    }
    .ShikshaHeadStory_right {
        border-radius: 0px;
        padding: 30px;
    }
    .ShikshaHeadStory_left {
        width: 100%;
        border-radius: 0px;
        border: 0px;
        border-top: 1px solid grey;
    }

    .ShikshaHeadStory_right {
        width: 100%;
        height: fit-content;
        font-size: 16px;
    }
}
