.AdminMentorList{
    padding: 100px 0px;
    background-color: skyblue;
    min-height: 100vh;
    width: 100vw;
}
.AdminMentorList_TableCover{
    width: 90%;
    margin: auto;
    overflow: scroll;
}
.AdminMentorList table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    min-width: 1400px;
    margin: auto;
    background-color: white;
   
}

.AdminMentorList td,
th {
    border: 1px solid lightgrey;
    text-align: left;
    padding: 8px;
    
}

.AdminMentorList tr:nth-child(even) {
    background-color: #dddddd;
}
.longtext_mentor {
   min-width: 400px;
   height : 150px ; 
   overflow-y: scroll;
   display: flex;
   white-space:pre-wrap;
}