.DonateOne{
    width:100%;
    /* min-height: calc(200vh );
    background-image: url("https://shikshawelfare.com/media/donatePoster_desktop.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: overlay;
    background-color:rgb(82, 79, 79);
    margin-top : -150px ; */
}

.DonateOne_box{
    border: 1px solid lightgray ;
    padding : 50px 30px ;
    max-width : 50vw;
    height: 100%;
    margin:auto;
    background-color: white;
    text-align: left;
}


.DonateOne_box > h1{
    color: rgb(14, 13, 13);
    background-color:orange;
    padding : 0px 20px;
    font-weight: 800;
    margin: 0px 30px 20px 20px;
}
.DonateOne_BgHeading {
    font-size: 18px;
    width: 90%;
    margin: 30px auto;
    font-weight: 500;
    color: rgb(92, 89, 89);
}
.DonateOne_box_Amounts{
    /* border : 1px solid lightgray ; */
    margin: 30px auto;
    width: 80%;
}
.DonateOne_box_Amounts >button {
    border:1px solid lightgray;
    color: rgb(87, 87, 87);
    background-color: white;
    padding : 10px 10px ; 
    width: 30%;
    height: 100%;
    font-size: 25px; 
}
.DonateOne_box_Amounts>button:hover {
    background-color: whitesmoke;
}
.DonateOne_Amount>label{
    font-size: 25px;
   margin-top: 5px;
   margin-right: 10px;
   color: rgb(78, 78, 78);
}
.DonateOne_Amount>input {
  padding: 5px 10px ;
  font-size: 20px;
  width: 60%;
  border : 1px solid lightgray ; 
  background-color: white;
  border-radius: 10px;
  transition: all 500ms ;
}
.DonateOne_Amount>input:focus {
  border : 1px solid grey;
  background-color: whitesmoke;
  outline: none;
  transform: scale(1.05);
}
.DonateOne_box>button {
    margin: 30px auto;
    width: 80%;
    background-color: rgb(250, 183, 59);
    color: white;
    border-radius: 20px;
    border:0 ; 
    font-size: 30px;
    padding: 5px 0;
}
.DonateOne_box > img{
   max-width : 100%;
    object-fit: contain;
}

/*  ------------------------------------------------------------------------- */
.DonateOne_box_2{
     border: 1px solid lightgray;
     padding : 50px 30px;
    width : 40vw;
    height: 100%;
     background-color: white;
    
     top: 200px;
     right: 100px;
     border-radius: 10px;
}
.DonateOne_box_2 > h3 {
    color: rgb(94, 92, 92);
}
.DonateFormTwo_detail{
    width: 100%;
}
.DonateFormTwo_detail > input {
    width: 100%;
    padding: 5px;
    font-size: 18px;
    margin: 10px  ;
}
.DonateOne_box_2 > button{
    margin: 30px auto;
    width: 100%;
    background-color: rgb(250, 183, 59);
    color: white;
    border-radius: 20px;
    border:0;
    font-size: 30px;
    padding: 5px 0;
}
@media (max-width: 767px) {
    .DonateOne{
    background-size: cover;
    background-image: url("https://shikshawelfare.com/media/donatePoster_Mobile_bg.jpg");

    }
    .DonateOne_box {
        position: relative;
        width: 95vw;
        margin: auto 0 ;
        right: 0;
        top : -60px ;
        padding : 50px 10px;
        border-radius: 20px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

    }
    .DonateOne_box>img {
        width: 90vw;
        object-fit: contain;
    }

    .DonateOne_box_2 {
       position: relative;
       width: 95vw;
       margin: auto;
       right: 0;
    }
}

.DonateOne_bankDetails{
    text-align: left;
    margin-left: 20px;
}