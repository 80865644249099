.AdminMentee{
    width: 100%;
    padding: 150px 0px;
}
.AdminMentee_Table {
    overflow:scroll ;
    width: 1400px;
    max-width: 90vw;
    margin: auto;
}
.AdminMentee_Table > table {
     width: 1400px;
    border: 1px solid grey;

}
.AdminMentee_Table th {
    padding: 5px 10px;
    border: 1px solid grey;
    text-align: left;
}
.AdminMentee_Table  td {
    padding: 5px 10px; 
    border: 1px solid grey ; 
    text-align: left;
}