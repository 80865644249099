.AdminStories{
    min-height: 100vh;
    padding: 200px 0px;background-color :lightgrey;
}
.AdminStories_cover{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.AdminStories_cover_button{
    width: 95%;
    margin: 0 30px;
    margin-top: -30px;
    border-radius:20px;
}
.AdminShikshaStories_Content {
    display: flex;
    overflow-x: scroll;
    max-width: 90vw;
    margin:auto;
    justify-content: flex-start;
    align-items: flex-start ;
}
.AdminShikshaStories_Content > table{
    border: 1px solid grey;
    background-color:white ; 
    text-align: left;
}
.AdminShikshaStories_Content th {
    border-right: 1px solid grey;
    padding: 3px 10px;
    text-align: center;

}
.AdminShikshaStories_Content td {
    border: 1px solid grey;
    padding: 3px 10px ; 
    min-width: 150px;
    max-width: 400px;
    vertical-align:top ;
}
.AdminShikshaStories_Content button {
    border: none ;
    background-color: transparent ;
    padding: 3px 10px;
}
.ShowOneLineOnly{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    border: none ;
    -webkit-box-orient: vertical;
}