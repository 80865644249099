@import url('https://fonts.googleapis.com/css2?family=New+Tegomin&family=Poppins:wght@200&display=swap');

.Donate{
    padding-bottom: 50px;
    background-color : white ; 
    /* padding-top: 60px; */
}
.Donate_HeadImg {
    width: 100vw;
    min-height: 400px ; 
    padding-top: 100px ;
    background-color : #fff9f4 ; 
    object-fit: contain;
    border-bottom: 3px solid rgba(250, 185, 65,0.3);
}
.Donate_HeadContent{
    position: absolute;
    top : 0px;
    left: 0px;
    width: 50vw;
    padding-top: 100px;
}
.Donate_HeadContent >h2 {
     font-family: 'New Tegomin',
     serif;
      width: 80%;
      margin: 0 auto;
     font-size: 3vw;
    text-align: left;
     font-weight: 800 ; 
}
.Donate_HeadContent>p {
    font-size: 1.5vw;
    width: 80%;
    margin: 0 auto;
   text-align: left;
    font-weight: 500;
    font-family: 'Poppins',
        sans-serif;
}
@media (max-width: 767px) {
    .Donate_HeadContent {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 300px ; 
        padding-top: 50px;
    }
    .Donate_HeadContent>h2 {
        font-size: 8vw;
    }
    .Donate_HeadContent>p {
        font-size: 4vw;
    }
    .Donate_HeadImg {
      padding-top: 300px;
      content : url("https://firebasestorage.googleapis.com/v0/b/shiksha-welfare.appspot.com/o/Donation%2FDonatePoster_HeadMobile.jpeg?alt=media&token=854bb766-aca4-4abe-b928-afe622a908d2");
    }
}