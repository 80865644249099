.ConnectToShiksha{
    width: 1000px;
    max-width: 90vw;
    margin: 30px auto;
    padding: 30px;
    border:  1px solid lightgray;
}
.ConnectToShiksha_detail {
    width: 100%;
}

.ConnectToShiksha_detail>input {
    width: 100%;
    padding: 5px;
    font-size: 18px;
    margin: 10px;
    border : none ; 
    border-bottom: 1px solid grey;
}

.ConnectToShiksha_form>button {
    margin: 30px auto;
    width: 80%;
    background-color: skyblue;
    border: 1px solid blue;
    color: black;
    font-family: 'Courier New', Courier, monospace;
    border-radius: 20px;
    border: 0;
    font-size: 30px;
    padding: 5px 0;
}
@media (max-width: 768px) {
.ConnectToShiksha_form>button {
    font-size: 25px;
    width: 100%;
}
}